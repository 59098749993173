import { useState } from "react";
import { useRecoilState } from "recoil";
import { advanceInfo, AdvanceInfo } from "../../state/advance-state";
import PaymentDetailsModal from "../paymentDetailsModal";
import DataTable from "./index";
import { AdvanceTableColumns } from "./types/datatableTypes";

export type TransactionDatatableProps = {
  fixedHeader: boolean;
  props: any;
};

export const AdvancesDatatable = (
  props: TransactionDatatableProps
): JSX.Element => {
  const [displayState, setDisplayState] = useState(false);
  const [opportunityId, setOpportunityId] = useState<string>("");
  const [vendorName, setVendorName] = useState<string>("");
  const [advancesInformation, setAdvancesInformation] =
    useRecoilState(advanceInfo);

  const toggleModalDialog = () => {
    setDisplayState(!displayState);
  };

  const onRowDoubleClicked = (row: AdvanceInfo) => {
    setDisplayState(true);
    setOpportunityId(row.opportunityId);
    setVendorName(row.vendorName);
  };

  return (
    <>
      <DataTable<AdvanceInfo>
        hideExportBtn={false}
        fixedHeader={props.fixedHeader}
        onRowDoubleClicked={onRowDoubleClicked}
        columns={AdvanceTableColumns}
        data={advancesInformation}
      />
      <PaymentDetailsModal
        vendorName={vendorName}
        props={props.props}
        opportunityId={opportunityId}
        isOpen={displayState}
        toggleModalDialog={toggleModalDialog}
      ></PaymentDetailsModal>
    </>
  );
};

export default AdvancesDatatable;
