export const FormatNumber = (
  number: number,
  type: string,
  minFractionDigits?: number,
  maxFractionDigits?: number
) => {
  let formatOptions: Intl.NumberFormatOptions = {};
  switch (type) {
    case "currency":
      formatOptions = {
        style: "currency",
        currency: "CAD",
        minimumFractionDigits: minFractionDigits ?? 0,
        maximumFractionDigits: maxFractionDigits ?? 2,
      };
      const formatter = new Intl.NumberFormat("en-CA", formatOptions);
      return formatter.format(number);
    case "integer":
      return number;
    default:
      throw new Error("Wrong format!");
  }
};
