import moment from "moment";
import { TableColumn } from "react-data-table-component";
import IAdvanceInformation from "../../../interfaces/IAdvanceInformation";
import IAdvancePaymentInformation from "../../../interfaces/IAdvancePaymentInformation";

export type TabitDatatableCustomProps = {
  DisplayTopRows: object;
};

export type TransactionTableDatatableColumn = IAdvancePaymentInformation;

function formatNumberToCurrency(number: number | undefined | null, decimals: number) : string {
  let formatter = new Intl.NumberFormat('en-CA', { style: 'currency', currency: 'CAD', maximumFractionDigits: decimals, minimumFractionDigits: decimals });
  if (number === undefined || number === null)
    return "N/A";
  return formatter.format(number);
}

export const AdvanceTableColumns: TableColumn<IAdvanceInformation>[] = [
  {
    name: "VENDOR",
    selector: (row) => row.vendorName,
    sortable: true,
  },
  {
    name: "VENDOR ORDER ID",
    selector: (row) => row.vendorOrderId,
    sortable: true,
  },
  {
    name: "TABIT TRANSACTION ID",
    selector: (row) => row.transactionNumber,
    sortable: true,
  },
  {
    name: "BALANCE",
    selector: (row) => formatNumberToCurrency(row.balance, 2),
    sortable: true,
  },
  {
    name: "PRINCIPLE AMOUNT",
    selector: (row) => formatNumberToCurrency(row.principalAmount, 2),
    sortable: true,
  },
  {
    name: "TOTAL REPAYMENT AMOUNT",
    selector: (row) => formatNumberToCurrency(row.totalRepaymentAmount, 2),
    sortable: true,
  },
  {
    name: "DATE",
    selector: (row) => moment(row.purchaseDate).format("YYYY/MM/DD"),
    sortable: true,
  },
  {
    name: "PAYMENT AMOUNT",
    selector: (row) => formatNumberToCurrency(row.paymentAmount, 2),
    sortable: true,
  },
  {
    name: "NUMBER OF PAYMENTS",
    selector: (row) => row.noOfInstallments && row.noOfInstallments.toFixed(0),
    sortable: true,
  },
];

export const TrasactionTableColumns: TableColumn<IAdvancePaymentInformation>[] =
  [
    {
      name: "PAYMENT DATE",
      selector: (row) => moment(row.paymentDate).format("YYYY/MM/DD"),
      sortable: true,
    },
    {
      name: "NSF AMOUNT",
      selector: (row) => formatNumberToCurrency(row.nsfAmount, 2),
      sortable: true,
    },
    {
      name: "TOTAL PAYMENT AMOUNT",
      selector: (row) => formatNumberToCurrency(row.totalPaymentAmount, 2),
      sortable: true,
    },
    {
      name: "BALANCE",
      selector: (row) => formatNumberToCurrency(row.balance, 2),
      sortable: true,
    },
    {
      name: "PAYMENT STATUS",
      selector: (row) => row.paymentStatus,
      sortable: true,
    },
  ];
