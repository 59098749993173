import { makePostRequest } from ".";

export const UserAuthAPI: UserApi = {
  login: async (payload) => {
    const url = `/auth/login`;
    return await makePostRequest<LoginRequest, LoginResponse>(
      payload,
      url,
      true
    );
  },

  sendResetPasswordLink: async (payload: SendResetPasswordLinkRequest) => {
    const url = `/auth/sendReset`;
    return await makePostRequest<SendResetPasswordLinkRequest, SendResetPasswordLinkResponse>(
      payload,
      url,
      true
    );
  },

  sendUsername: async (payload: SendUsernameRequest) => {
    const url = `/auth/sendUsername`;
    return await makePostRequest<SendUsernameRequest, SendUsernameResponse>(
      payload,
      url,
      true
    );
  },

  resetPassword: async (payload: SendResetPasswordRequest) => {
    const url = `/auth/reset`;
    return await makePostRequest<SendResetPasswordRequest, SendResetPasswordResponse>(
      payload,
      url,
      true
    );
  }

};

type UserApi = {
  login: (payload: LoginRequest) => Promise<LoginResponse>;
  sendResetPasswordLink: (
    payload: SendResetPasswordLinkRequest
  ) => Promise<SendResetPasswordLinkResponse>;
  sendUsername: (payload: SendUsernameRequest) => Promise<SendUsernameResponse>;
  resetPassword: (payload: SendResetPasswordRequest) => Promise<SendResetPasswordResponse>;
};

type LoginResponse = {
  data: {
    accessToken: string;
    accountName: string;
    email: string;
    firstName: string;
    lastName: string;
    id: string;
    phoneNumber: string;
    userName: string;
  };
};

type LoginRequest = {
  username: string;
  password: string;
  recaptchaResponse: string;
};

type SendResetPasswordLinkRequest = {
  username: string;
};

type SendResetPasswordLinkResponse = {};

type SendUsernameRequest = {
  email: string;
};

type SendUsernameResponse = {
  data: {};
};

type SendResetPasswordRequest = {
  password: string;
  token: string;
}

type SendResetPasswordResponse = {
}


