import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import  IPaymentBalance from "../interfaces/IPaymentBalanceInformation";

const { persistAtom } = recoilPersist();
export const paymentBalanceInfo = atom<PaymentBalanceInfo>({
    key: "paymentBalanceInfo",
    default: {
        paymentBalance : []
    },
    effects_UNSTABLE: [persistAtom]
})

export type PaymentBalanceInfo = {
 paymentBalance : IPaymentBalance[]
} 
