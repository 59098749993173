import "./styles/loader.scss";
import "./styles/loader-spinner.scss";
import { CSSProperties } from "react";

export const Loader = (props: LoaderProps) => {
  switch (props.mode) {
    case "loader":
      return (
        <div className="loader__Container">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/assests/tabit-loading-logo.svg"}
              className={"loader_Logo"}
              alt="Tabit"
            ></img>
          </div>
          <div className="loader__ProgressBar">
            <div className="loader__ProgressBarValue"></div>
          </div>
        </div>
      );
    case "loader-error":
      return (
        <div className="loader__Container">
          <div>
            <img
              src={process.env.PUBLIC_URL + "/assests/tabit-error.svg"}
              className={"loader_Logo"}
              alt="Tabit"
            ></img>
          </div>
          <small className="loader__Error">
            We detected a connection problem, please try again later
          </small>
        </div>
      );
    case "spinner":
      return (
        <div className="lds-ellipsis" style={props.style}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      );

    default:
      throw new Error("Unknown mode");
  }
};

export type LoaderProps = {
  mode: "loader" | "spinner" | "loader-error";
  style?: CSSProperties;
};
