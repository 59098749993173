import { Container, Row, Col } from 'reactstrap';
import './styles/header.scss';
import logo from '../../assets/tabit-login-logo.svg';
type HeaderProps = {
    props: any;
};


export const Header = (props: HeaderProps): JSX.Element => {
    return (
        <>
            <Row>
                <div className='header__TopSection'></div>
            </Row>
            {/* <Row>
                <div className='header__BottomSection'>
                    <div>
                        <img className='header__BottomSection_Image' src={logo}></img>
                    </div>
                </div>
            </Row> */}
             <Row className='header__tabitIcon__row'>
                <Col>
                    <div className='header__tabitIcon'></div>
                </Col>               
            </Row>
        </>
    );
}
