const colors: any = {
  0: "#e5e5e5",
  1: "#9B2C2C",
  2: "#D44949",
  3: "#DCA02D",
  4: "#387F95",
  5: "#48AE65",
};

const scoreText: any = {
  0: "Very Weak",
  1: "Weak",
  2: "Ok",
  3: "Good",
  4: "Strong",
  5: "Very Strong",
};

const getColor = (score: number, index: number) => {
  if (score > index) {
    return colors[score];
  }
  return colors[0];
};

const indicatorIndexes = [0, 1, 2, 3, 4];

const Indicators = ({ score }: { score: number }) => (
  <div>
    <p style={{ color: colors[score + 1], marginBottom: "0px" }}>
      <span>Password strength score: </span>
      {scoreText[score + 1]}
    </p>
    <div className="indicator-container">
      {indicatorIndexes.map((indicatorIndex) => (
        <div
          className="indicator"
          key={indicatorIndex}
          style={{ backgroundColor: getColor(score + 1, indicatorIndex) }}
        />
      ))}
    </div>
  </div>
);

export { Indicators };
