import { IconProps } from "./types/icon-props";

const DashboardIcon = (props: IconProps) => {
  const { style } = props;
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill={style.color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.5703 6.65625C21.5703 6.42187 21.4688 6.21484 21.3125 6.06641L18.4492 2.71094C18.2969 2.53516 18.0781 2.42969 17.8438 2.42969H6.14844C5.91406 2.42969 5.69531 2.53125 5.54297 2.71094L2.62109 6.14062C2.49609 6.28906 2.42969 6.47266 2.42969 6.65625C2.42969 6.65625 2.42969 6.65625 2.42969 6.66016V20.7695C2.42969 21.2109 2.78516 21.5664 3.22656 21.5664H20.7656C21.207 21.5664 21.5625 21.2109 21.5625 20.7695C21.5625 20.7695 21.5703 6.69141 21.5703 6.65625ZM19.9688 8.10156C19.9688 8.81641 19.3867 9.39844 18.6719 9.39844H18.4727C17.7578 9.39844 17.1758 8.81641 17.1758 8.10156V7.45312H19.9688V8.10156ZM15.582 8.10156C15.582 8.81641 15 9.39844 14.2852 9.39844H14.0859C13.3711 9.39844 12.7891 8.81641 12.7891 8.10156V7.45312H15.5781V8.10156H15.582ZM11.2031 8.10156C11.2031 8.81641 10.6211 9.39844 9.90625 9.39844H9.70703C8.99219 9.39844 8.41016 8.81641 8.41016 8.10156V7.45312H11.1992V8.10156H11.2031ZM6.82031 8.10156C6.82031 8.81641 6.23828 9.39844 5.52344 9.39844H5.32812C4.61328 9.39844 4.03125 8.81641 4.03125 8.10156V7.45312H6.82031V8.10156ZM6.52734 4.02734H17.4844L19.0469 5.85937H4.96484L6.52734 4.02734ZM10.8594 19.9727V15.8437H13.1523V19.9727H10.8594ZM14.7461 19.9727V15.0469C14.7461 14.6055 14.3906 14.25 13.9492 14.25H10.0625C9.62109 14.25 9.26562 14.6055 9.26562 15.0469V19.9727H4.03125V10.6836C4.42188 10.8789 4.85938 10.9922 5.32812 10.9922H5.52734C6.35156 10.9922 7.09375 10.6445 7.62109 10.0937C8.14844 10.6484 8.89062 10.9922 9.71484 10.9922H9.91406C10.7383 10.9922 11.4805 10.6445 12.0078 10.0937C12.5352 10.6484 13.2773 10.9922 14.1016 10.9922H14.3008C15.125 10.9922 15.8672 10.6445 16.3945 10.0937C16.9219 10.6484 17.6641 10.9922 18.4883 10.9922H18.6875C19.1523 10.9922 19.5938 10.8789 19.9844 10.6836V19.9727H14.7461Z"
        fill={style.color}
      />
    </svg>
  );
};
export default DashboardIcon;
