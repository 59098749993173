import { useEffect } from "react";
import { Loader } from "../components/loader/loader";
import { NavigationContainer } from "../components/navigation";
import { useRecoilValue, useSetRecoilState, useRecoilState } from "recoil";
import { loadingState } from "../state/loading-state";
import { DataAPI } from "../apis/data";
import { advanceInfo, AdvanceInfo } from "../state/advance-state";
import { paymentBalanceInfo } from "../state/paymentBalance-state";
import { authState } from "../state/authState";
import { WidgetAPI } from "../apis/widgetInformation";
import { AdvanceDropDownOptionsType } from "../components/navigation/types/AdvanceDropDownOptionsType";
import { useNavigate } from "react-router-dom";
import { advancePaymentInfo } from "../state/advancePayment-state";

export interface IDashboardProps {
  props?: any;
}

export default function MainPage() {
  const [state, setState] = useRecoilState(loadingState);
  const setWidgetsInformation = useSetRecoilState(paymentBalanceInfo);
  const authenticationState = useRecoilValue(authState);

  const [advancesInformation, setAdvancesInformation] =
    useRecoilState(advanceInfo);
  const [transactions, setTransactions] = useRecoilState(advancePaymentInfo);
  const [paymentBalanceInformation, setPaymentBalanceInformation] =
    useRecoilState(paymentBalanceInfo);

  const navigate = useNavigate();

  let options: AdvanceDropDownOptionsType[] = [];

  useEffect(() => {
    const init = async () => {
      switch (authenticationState.status) {
        case "not_authorized":
          navigate("/");
          break;
        default:
          setState("loading");
          try {
            await GetAdvancesInformation();
            await GetPaymentBalanceInformation();
            setState("ready");
          } catch (err) {
            setState("error");
          }
          break;
      }
    };
    init();

    const deleteSessionData = () => {
      setTransactions([]);
      setAdvancesInformation([]);
      setPaymentBalanceInformation({ paymentBalance: [] });
    };
    window.addEventListener("beforeunload", deleteSessionData);
    return () => {
      window.removeEventListener("beforeunload", deleteSessionData);
    };
  }, []);

  const GetAdvancesInformation = async () => {
    const result = await DataAPI.getAdvances(authenticationState.token);
    let advances: AdvanceInfo[] = [];
    options.push({
      label: "-- All --",
      value: "all",
    });
    if (result.data !== undefined && result.data.length > 0) {
      result.data.map((value, index) => {
        advances.push({
          vendorName: value.vendorName,
          vendorOrderId: value.vendorOrderId,
          opportunityId: value.opportunityId,
          transactionNumber: value.transactionNumber,
          balance: value.balance,
          principalAmount: value.principalAmount,
          totalRepaymentAmount: value.totalRepaymentAmount,
          purchaseDate: value.purchaseDate,
          paymentAmount: value.paymentAmount,
          noOfInstallments: value.noOfInstallments,
          totalServiceFee: value.totalServiceFee,
          remainingServiceFee: value.remainingServiceFee,
          remainingPrincipalAmount: value.remainingPrincipalAmount,
        });
        options.push({
          label: "Vendor: " + value.vendorName + ", Amount: $" + value.balance,
          value: value.opportunityId,
        });
      });
    }

    setAdvancesInformation(advances);
  };

  const GetPaymentBalanceInformation = async () => {
    const advancesInformation = await DataAPI.getAdvances(
      authenticationState.token
    );
    const result = await WidgetAPI.getPaymentBalanceInformation(
      authenticationState.token
    );
    const paymentBalances: any[] = result.data.map((r) => {
      return {
        balance: r.balance,
        nextPaymentDate: r.nextPaymentDate,
        nextPaymentAmount: r.nextPaymentAmount,
      };
    });
    setWidgetsInformation({
      paymentBalance: paymentBalances,
    });
  };

  switch (state) {
    case "loading":
      return <Loader mode="loader" />;
    case "error":
      return <Loader mode="loader-error" />;
    case "ready":
      return (
        <div>
          <NavigationContainer selectedLoanId="all" />
        </div>
      );
  }
}
