import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { AdvancePaymentsDatatable } from "../datatable/advancePaymentsDatatable";
import { Loader } from "../loader/loader";
import "./styles/paymentDetailsModal.scss";

type PaymentDetailsModalProps = {
  isOpen: boolean;
  vendorName: string;
  toggleModalDialog: any;
  props: any;
  opportunityId: string;
};

const PaymentDetailsModal = (props: PaymentDetailsModalProps): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (props.opportunityId !== undefined && props.opportunityId !== "") {
      setIsLoading(true);
    }
  }, [props.opportunityId]);

  return (
    <Modal
      modalClassName="navigation__paymentDetails__modal"
      size="xl"
      style={{ maxWidth: "90%" }}
      centered
      isOpen={props.isOpen}
      toggle={props.toggleModalDialog}
    >
      <Helmet>
        <title>
          "{props.vendorName}" Detailed Transactions - Tabit Buyer Portal
        </title>
      </Helmet>
      <ModalHeader toggle={props.toggleModalDialog}>Payments</ModalHeader>
      <ModalBody>
        <div id="navigation__paymentDetails__modal__loader">
          {isLoading ? <Loader mode="spinner"></Loader> : null}
        </div>
        <AdvancePaymentsDatatable
          isLoaderVisible={isLoading}
          setLoader={setIsLoading}
          opportunityId={props.opportunityId}
          fixedHeader={true}
          DisplayTopNRows={false}
          props={props}
        />
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default PaymentDetailsModal;
