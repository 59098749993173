import "./styles/forgotPasswordReset.scss";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import { useEffect, useState } from "react";
import { UserAuthAPI } from "../../apis/userAuth";
import { LoadingContainer } from "../loader/loader-container";
import { useNavigate, useSearchParams } from "react-router-dom";
import { zxcvbn, zxcvbnOptions } from "@zxcvbn-ts/core";
import * as zxcvbnCommonPackage from "@zxcvbn-ts/language-common";
import * as zxcvbnEnPackage from "@zxcvbn-ts/language-en";
import { FeedbackType } from "@zxcvbn-ts/core/dist/types";
import { Indicators } from "../indicators/Indicators";

type LoginProps = {
  children: JSX.Element | JSX.Element[];
  setDisplayType: any;
};

interface Indicator {
  score: number;
  feedback: FeedbackType;
}

export const ForgotPasswordResetContainer = (
  props: LoginProps
): JSX.Element => {
  const [loadingState, setLoadingState] = useState<
    "loading" | "ready" | "error" | "success"
  >("ready");
  const options = {
    translations: zxcvbnEnPackage.translations,
    graphs: zxcvbnCommonPackage.adjacencyGraphs,
    dictionary: {
      ...zxcvbnCommonPackage.dictionary,
      ...zxcvbnEnPackage.dictionary,
    },
  };

  zxcvbnOptions.setOptions(options);

  const [password, setPassword] = useState<string>("");
  const [message, setMessage] = useState<string>("reset success");
  const [indicator, setIndicator] = useState<Indicator | null>();
  const [passwordRepeat, setPasswordRepeat] = useState<string>("");
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoadingState("ready");
    const { score, feedback } = zxcvbn(password);
    if (password === "") return;
    if (password.length < 8) {
      setMessage("Password should be longer than 8 characters.");
      setLoadingState("error");
    }
    if (password !== passwordRepeat) {
      setMessage("Entered passwords are not matching.");
      setLoadingState("error");
    }
    if (score < 3) {
      setMessage("Password is not strong enough.");
      setLoadingState("error");
    }
    setIndicator({ score, feedback });
  }, [password, passwordRepeat]);

  const score = indicator ? indicator.score : -1;
  const feedback = indicator ? indicator.feedback : undefined;

  const showButtonTitle = () => {
    const mode = searchParams.get("mode");
    switch (mode) {
      case "set":
        return "Set password";
      case "reset":
        return "Reset password";
      default:
        return "Reset password";
    }
  };

  return (
    <Row md="12">
      <Col>
        <Form
          inline
          className="fade-in-bottom"
          style={{ display: loadingState === "success" ? "none" : "block" }}
        >
          <FormGroup>
            <Label
              className="forgotPasswordReset__passwordLabel"
              for="forgotPasswordReset__passwordTxt"
            >
              Password
            </Label>
            <Input
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              id="forgotPasswordReset__passwordTxt"
              name="password"
              placeholder="Enter password"
              type="password"
            />
          </FormGroup>
          <div className="d-block">
            <div>
              <p
                style={{
                  fontSize: "11px",
                  marginBottom: "0px",
                  marginTop: "-10px",
                }}
              >
                Password should be:
                <ol>
                  <li>at least 8 characters long</li>
                  <li>have "Strong" score</li>
                </ol> 
              </p>
              {password !== "" && <Indicators score={score} />}
              {feedback && feedback.warning && feedback.warning.length > 0 && (
                <div>
                  <p style={{ fontSize: "11px", marginBottom: "0px" }}>Tips:</p>
                  <ol>
                    {feedback.suggestions.map((suggestion) => (
                      <li style={{ fontSize: "11px" }} key={suggestion}>
                        {suggestion}
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
          </div>
          <FormGroup>
            <Label
              className="forgotPasswordReset__passwordRepeatLabel"
              for="forgotPasswordReset__passwordRepeatTxt"
            >
              Repeat Password
            </Label>
            <Input
              value={passwordRepeat}
              onChange={(e) => {
                setPasswordRepeat(e.target.value);
              }}
              id="forgotPasswordReset__passwordRepeatTxt"
              name="password"
              placeholder="Repeat password"
              type="password"
            />
          </FormGroup>
          <Button
            className="forgotPasswordReset__resetPasswordButton"
            disabled={
              password.length < 8 || score < 3 || password !== passwordRepeat
            }
            onClick={async () => {
              try {
                setMessage("");
                const accessToken = searchParams.get("token");
                if (!accessToken)
                  throw new Error("Please provide access token");
                await UserAuthAPI.resetPassword({
                  password,
                  token: accessToken,
                });
                setLoadingState("loading");
                setMessage("Your password has been successfully reset");
                setLoadingState("success");
              } catch (err: any) {
                const mode = searchParams.get("mode");
                if (mode == "set") setMessage("Unable to setup password");
                else {
                  const { message } = err;
                  const error = message || err?.response?.data?.message;
                  setMessage(error ?? "Unable to reset password");
                }
                setLoadingState("error");
              }
            }}
          >
            {showButtonTitle()}
          </Button>
        </Form>
        <LoadingContainer state={loadingState} message={message} />
        <div
          className="forgotPasswordReset__backToLoginContainer"
          style={{ display: loadingState === "success" ? "block" : "none" }}
        >
          <div
            className="forgotPasswordReset__backToLogin"
            onClick={() => {
              props.setDisplayType("Login");
              navigate("/");
            }}
          >
            Get back to log in
          </div>
        </div>
      </Col>
    </Row>
  );
};
