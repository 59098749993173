import { LoginContainer } from "../containers/login";
import { HeaderContainer } from "../containers/header";
import { FooterContainer } from "../components/footer";
import { CustomModal } from "../components/modal";
import { useRecoilState } from "recoil";
import { authState } from "../state/authState";
import { Container } from "reactstrap";
import config from "../config/config";
import { Helmet } from "react-helmet";

export interface ILoginProps {
  props?: any;
}

export default function LoginPage(props: ILoginProps) {

  const [auth, setAuth] = useRecoilState(authState);

  const ModalContainer = () => {
    
    return (
    <CustomModal
      show={auth.status === "logged_out" || auth.status === "idle_logged_out"}
      content={auth.status === "logged_out" ? "You were logged out" : `You were logged out, as the active session is idle for more than ${config.IDLE_TIME_OUT_IN_MINUTES} minutes.`}
      closeAction={() => setAuth({ status: "not_authorized" })}
      key={"log_in_modal"}
    />)
  };

  return (
    <Container fluid>
      <Helmet>
        <title>Login - Tabit Buyer Portal</title>
      </Helmet>
      <ModalContainer />
      <HeaderContainer />
      <LoginContainer {...props} />
      <FooterContainer
        displayCopyright={true}
        displayLegal={true}
        displaySocial={true}
      />
    </Container>
  );
}
