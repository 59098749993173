import { atom } from "recoil"
import { recoilPersist } from "recoil-persist"

const { persistAtom } = recoilPersist();

export const authState = atom<AuthState>({
    key: "authState",
    default: {
        status: "not_authorized"
    },
    effects_UNSTABLE: [persistAtom]
})

export type AuthState = {
  status: "authorized" | "not_authorized" | "expired" | "logged_out" | "idle_logged_out",
  userId?: string; 
  userName?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  token?: string;
} 
