import "./styles/policy.scss";

export const PrivacyPolicy = () => {
  return (
    <div className="PrivacyPolicy__container">
      <div className="PrivacyPolicy__title">Tabit Privacy Policy</div>
      <br></br>
      <div className="PrivacyPolicy__description">
        This Privacy Policy applies to <a href="https://www.tabit.ai" target="_blank">www.tabit.ai</a>{" "}
        (the “Site”) owned and operated by Merchant Growth Ltd. (doing business
        as Tabit) (“Merchant Growth”) and to your application for, access to or
        receipt of products and services from Merchant Growth. Merchant Growth
        is committed to keeping your personal information confidential and
        secure. This Privacy Policy describes how Merchant Growth collects,
        uses, protects, shares, and retains your information. It also describes
        the choices available to you regarding the use of, your access to, and
        how to update and correct your personal information.
      </div>
      <br></br>
      <div className="PrivacyPolicy__title">Scope and Definitions</div>
      <br></br>
      <div className="PrivacyPolicy__description">
        This notice describes the privacy practices of Merchant Growth and its
        subsidiaries and affiliates. “We” and “us” means Merchant Growth, our
        subsidiaries and affiliates, and any of our respective agents and third
        party service providers (“service providers”). “You” means a website
        visitor, applicant, customer, former customer, an owner of, or reference
        or guarantor for, an applicant. Personal information means any factual
        or subjective information in recorded form, that identifies or could be
        used to identify an individual.
      </div>
      <br></br>
      <div className="PrivacyPolicy__title">
        Collection of Personal Information
      </div>
      <br></br>
      <div className="PrivacyPolicy__description">
        By submitting your personal information to Merchant Growth or our
        service providers and agents, you agree that Merchant Growth may
        collect, use and disclose such personal information in accordance with
        this Privacy Policy. If you apply for Merchant Growth products or
        services, the application and any terms and conditions specific to your
        account may also describe additional purposes for which we collect, use,
        disclose, and retain personal information.
      </div>
      <br></br>
      <div className="PrivacyPolicy__description">
        The personal information that Merchant Growth collects and uses varies
        depending on our interactions with you. Our objective when collecting
        and using personal information is to maintain and improve the
        relationship we have with you; provide you with information about
        products and services that you will value; reduce the cost associated
        with maintaining your (and others’) accounts so that we may continue to
        provide you with a fairly priced product; reduce unwanted or
        inappropriate marketing; and protect you from unauthorized access to and
        use of your account or personal information.
      </div>
    </div>
  );
};
