import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { DataAPI } from "../../apis/data";
import IAdvancePaymentInformation from "../../interfaces/IAdvancePaymentInformation";
import {
  advancePaymentInfo,
  AdvancePaymentInfo,
} from "../../state/advancePayment-state";
import { authState } from "../../state/authState";
import DataTable from "./index";
import {
  TransactionTableDatatableColumn,
  TrasactionTableColumns,
} from "./types/datatableTypes";

export const AdvancePaymentsDatatable = (props: any): JSX.Element => {
  const [authenticationState, setAuthState] = useRecoilState(authState);
  const [currentTransactionToView, setCurrentTransactionsToView] = useState<
    IAdvancePaymentInformation[]
  >([]);
  const [transactions, setTransactions] = useRecoilState(advancePaymentInfo);

  useEffect(() => {
    if (props.opportunityId !== undefined && props.opportunityId !== "")
      GetAllTransactions();
  }, []);

  const GetAllTransactions = async () => {
    let transactionObj: AdvancePaymentInfo = { id: "", values: [] };
    let recentTransactionRows: IAdvancePaymentInformation[] = [];
    let transactionForOpportunityId: AdvancePaymentInfo[] = JSON.parse(
      JSON.stringify(transactions)
    );
    let result: AdvancePaymentInfo[] = [];

    if (transactions.length > 0) {
      result = transactions.filter((value, index) => {
        if (value.id === props.opportunityId) {
          return value;
        }
      });
    }

    if (result.length == 0) {
      const recentTransactions =
        await DataAPI.getAllTransactionsForOpportunityId(
          props.opportunityId,
          authenticationState.token
        );
      if (
        recentTransactions.data !== undefined &&
        recentTransactions.data.length > 0
      ) {
        transactionObj.id = props.opportunityId;
        recentTransactions.data.map((value, index) => {
          recentTransactionRows.push({
            balance: value.balance,
            paymentDate: value.paymentDate,
            paymentStatus: value.paymentStatus,
            nsfAmount: value.nsfAmount,
            totalPaymentAmount: value.totalPaymentAmount,
          });
        });
        transactionObj.values = recentTransactionRows;
      }
      transactionForOpportunityId.push(transactionObj);
      setTransactions(transactionForOpportunityId);
      setCurrentTransactionsToView(recentTransactionRows);
    } else {
      setCurrentTransactionsToView(result[0].values);
    }
    props.setLoader(false);
  };
  return (
    <>
      {!props.isLoaderVisible ? (
        <DataTable<TransactionTableDatatableColumn>
          noHeader={false}
          fixedHeader={true}
          hideExportBtn={false}
          columns={TrasactionTableColumns}
          data={currentTransactionToView}
        />
      ) : null}
    </>
  );
};

export default AdvancePaymentsDatatable;
