import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import IAdvancePaymentInformation from "../interfaces/IAdvancePaymentInformation";

const { persistAtom } = recoilPersist();

export const advancePaymentInfo = atom<AdvancePaymentInfo[]>({
  key: "advancePaymentInfo",
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export type AdvancePaymentInfo =  {
  id: string;
  values: IAdvancePaymentInformation[]
}
