import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookSquare,
  faTwitterSquare,
  faLinkedinIn,
  faInstagramSquare,
} from "@fortawesome/free-brands-svg-icons";
import "./styles/footer.scss";
import { FooterProps } from "./types/footer-props";
import { Row } from "reactstrap";
import { CustomModal } from "../modal";
import { useState } from "react";
import { PrivacyPolicy } from "../privacyPolicy";
import { DeviceType } from "../../utils/deviceType";

export const FooterContainer = (props: FooterProps): JSX.Element => {
  const { displayCopyright, displayLegal, displaySocial, className } = props;

  const [show, setShow] = useState<boolean>(false);

  const setVisibility = (display: boolean) => (display ? "visible" : "hidden");

  const getClassName = () =>
    "footer" + (className === undefined ? " login__Footer" : ` ${className}`);

  const displayDeviceType = DeviceType();

  let modalSize: "lg" | "sm" | "md" = "lg";
  switch (displayDeviceType) {
    case "desktop":
      modalSize = "lg";
      break;
    case "mobile":
      modalSize = "sm";
      break;
    case "tablet":
      modalSize = "md";
      break;
  }

  const ModalContainer = () => (
    <CustomModal
      show={show}
      size={modalSize}
      content={<PrivacyPolicy />}
      closeAction={() => setShow(false)}
      key={"log_in_modal"}
    />
  );

  const OpenSocialMediaSites = (mediaType: string) =>{
    switch(mediaType){
      case "facebook":
        window.open("https://www.facebook.com/Tabit-108090158253342");
        break;
      case "instagram":
        window.open("https://www.instagram.com/tabit.ai/");
        break;
      case "twitter":
        window.open("https://twitter.com/tabit_ai");
        break;
      case "linkedin":
        window.open("https://www.linkedin.com/company/paywithtabit/");
        break;
    }

  }

  return (
    <Row>
      <ModalContainer></ModalContainer>
      <div className={getClassName()}>
        <div
          className="footer__followBlock"
          style={{ visibility: setVisibility(displaySocial) }}
        >
          <div className="footer__followUsDiv">Follow us</div>
          <span className="footer__socialMediaSpan" onClick={()=>OpenSocialMediaSites("facebook")}>
            <FontAwesomeIcon icon={faFacebookSquare} />
          </span>
          <span className="footer__socialMediaSpan" onClick={()=>OpenSocialMediaSites("twitter")}>
            <FontAwesomeIcon icon={faTwitterSquare} />
          </span>
          <span className="footer__socialMediaSpan" onClick={()=>OpenSocialMediaSites("linkedin")}>
            <FontAwesomeIcon icon={faLinkedinIn} />
          </span>
          <span className="footer__socialMediaSpan" onClick={()=>OpenSocialMediaSites("instagram")}>
            <FontAwesomeIcon icon={faInstagramSquare} />
          </span>
          <span className="footer__socialMediaSpan">hello@tabit.ai</span>
        </div>
        <div style={{ visibility: setVisibility(displayCopyright) }}>
          <span>
            &#169;Copyright 2022. All Rights Reserved |&nbsp;
            <span className="footer__legalLink" onClick={() => setShow(true)}>
              Legal
            </span>
          </span>
        </div>
        <div style={{ visibility: setVisibility(displayLegal) }}></div>
      </div>
    </Row>
  );
};
