import { makeGetRequest } from ".";

export const WidgetAPI: WidgetApi = {
  getPaymentBalanceInformation: async (token?: string) => {
    const url = `/Widget/GetPaymentBalanceInformation`;
    return await makeGetRequest<
      PaymentBalanceInfoRequest,
      PaymentBalanceInfoResponse
    >(url, true, token);
  },
};

type WidgetApi = {
  getPaymentBalanceInformation: (
    token?: string
  ) => Promise<PaymentBalanceInfoResponse>;
};

type PaymentBalanceInfoResponse = {
  data: any[];
};

type PaymentBalanceInfoRequest = {
  type: string;
};
