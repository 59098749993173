import "./styles/forgotUsername.scss";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import { Loader } from "../loader/loader";
import { useState } from "react";
import { UserAuthAPI } from "../../apis/userAuth";
import { LoadingContainer } from "../loader/loader-container";

type LoginProps = {
  children: JSX.Element | JSX.Element[];
  setDisplayType: any;
};

export const ForgotUsernameContainer = (props: LoginProps): JSX.Element => {
  const backToLoginClick = () => {
    props.setDisplayType("Login");
  };

  const [email, setEmail] = useState<string>("");

  const [loadingState, setLoadingState] = useState<
    "loading" | "ready" | "error" | "success"
  >("ready");

  const [message, setMessage] = useState<string>("");

  return (
    <Row md="12">
      <Col>
        <Form inline className="fade-in-left">
          <FormGroup>
            <Label
              className="forgotUsername__phoneNumberLabel"
              for="forgotUsername__emailTxt"
            >
              Your email address
            </Label>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              id="forgotUsername__emailTxt"
              name="email"
              placeholder="Enter email address"
              type="text"
            />
          </FormGroup>
          <Button
            onClick={async () => {
              try {
                setLoadingState("loading");
                await UserAuthAPI.sendUsername({ email });
                setMessage("Your username recovery email has been sent.")
                setLoadingState("success");
              } catch (err) {
                setMessage("Unable to send username!")
                setLoadingState("error");
              }
            }}
            className="forgotUsername__restoreUsernameBtn"
          >
            Send Username
          </Button>
          <div className="forgotUsername__backToLoginDiv">
            <small
              className="forgotUsername__backToLoginBtn"
              onClick={() => backToLoginClick()}
            >
              Back to login
            </small>
          </div>
        </Form>
        <LoadingContainer state={loadingState} message={message}/>
      </Col>
    </Row>
  );
};
