import { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { useRecoilState } from "recoil";
import { UserAuthAPI } from "../apis/userAuth";
import { ForgotLoginDetails } from "../components/forgotLoginDetails";
import { ForgotPasswordContainer } from "../components/forgotPassword";
import { ForgotPasswordResetContainer } from "../components/forgotPasswordReset/Index";
import { ForgotUsernameContainer } from "../components/forgotUsername";
import { LoadingContainer } from "../components/loader/loader-container";
import { LoginForm, ResetPassword, SignIn } from "../components/login/index";
import { LoginTabitImage } from "../components/loginTabitMapleImage";
import { Recaptcha } from "../components/recaptcha";
import { useMatchedRoute } from "../hooks/usePathPattern";
import { authState, AuthState } from "../state/authState";
import { GetErrorMessage } from "../utils/utilities";
import "./styles/login.scss";

type DisplayType =
  | "Login"
  | "ForgotPassword"
  | "ForgotUsername"
  | "ResetPassword";

export const LoginContainer = (props: any): JSX.Element => {
  const [authorizationState, setAuthorizationState] =
    useRecoilState<AuthState>(authState);

  const [loadingState, setLoadingState] = useState<
    "loading" | "ready" | "error" | "success"
  >("ready");

  const [message, setMessage] = useState<string>("");

  const navigate = useNavigate();

  const route = useMatchedRoute();
  const getDisplayTypeFromRoute = () => {
    switch (route?.path) {
      case "/reset":
        return "ResetPassword";
      default:
        return "Login";
    }
  };

  const [searchParams] = useSearchParams();
  useEffect(() => {
    switch (authorizationState.status) {
      case "authorized":
        const redirectUrl = searchParams.get("redirectUrl");
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate("main");
        }
        break;
    }
  }, [authorizationState]);

  const showTitle = () => {
    const mode = searchParams.get("mode");
    switch (mode) {
      case "set":
        return "Set password";
      case "reset":
        return "Reset password";
      default:
        return "Reset password";
    }
  };

  const [displayType, setDisplayType] = useState<DisplayType>(
    getDisplayTypeFromRoute()
  );

  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const login = async (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>,
    username: string,
    password: string
  ) => {
    e.preventDefault();
    try {
      setLoadingState("loading");

      const recaptchaResponse =
        (await recaptchaRef.current?.executeAsync()) ?? "";

      const result = await UserAuthAPI.login({
        username,
        password,
        recaptchaResponse,
      });

      const {
        accessToken,
        accountName,
        firstName,
        lastName,
        email,
        id,
        phoneNumber,
      } = result.data;

      setAuthorizationState({
        status: "authorized",
        firstName: firstName,
        lastName: lastName,
        token: accessToken,
        userId: id,
        userName: accountName,
        email: email,
        phone: phoneNumber,
      });

      setLoadingState("ready");
    } catch (error) {
      recaptchaRef.current?.reset();
      setLoadingState("error");
      setMessage(`Unable to log in. ${GetErrorMessage(error)}`);
    }
  };

  const setPage = (type: DisplayType) => {
    setDisplayType(type);
  };

  const displayContainer = (): JSX.Element => {
    switch (displayType) {
      case "Login":
      default:
        return (
          <>
            <SignIn />
            <div className="login__form fade-in-bottom">
              <LoginForm login={login}></LoginForm>
              <ForgotLoginDetails {...props} setDisplayType={setPage} />
              <LoadingContainer state={loadingState} message={message} />
            </div>
            <Recaptcha
              ref={recaptchaRef}
              onChange={(response: string | null) => {}}
              onExpired={() => {}}
            />
          </>
        );
      case "ForgotPassword":
        return (
          <>
            <SignIn />
            <div className="login__form">
              <ForgotPasswordContainer
                {...props}
                setDisplayType={setPage}
              ></ForgotPasswordContainer>
            </div>
            <Recaptcha
              ref={recaptchaRef}
              onChange={() => {}}
              onExpired={() => {}}
            />
          </>
        );
      case "ForgotUsername":
        return (
          <>
            <SignIn />
            <div className="login__form">
              <ForgotUsernameContainer
                {...props}
                setDisplayType={setPage}
              ></ForgotUsernameContainer>
            </div>
            <Recaptcha
              ref={recaptchaRef}
              onChange={() => {}}
              onExpired={() => {
                recaptchaRef.current?.execute();
              }}
            />
          </>
        );
      case "ResetPassword":
        return (
          <>
            <ResetPassword title={showTitle()} />
            <div className="login__form">
              <ForgotPasswordResetContainer
                {...props}
                setDisplayType={setPage}
              ></ForgotPasswordResetContainer>
            </div>
          </>
        );
    }
  };
  return (
    <Row>
      <LoginTabitImage />
      <Col sm={12} className="login__container">
        <Container className="login__div">{displayContainer()}</Container>
      </Col>
    </Row>
  );
};
