import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSetRecoilState, useRecoilState } from "recoil";
import { advanceInfo } from "../../state/advance-state";
import { advancePaymentInfo } from "../../state/advancePayment-state";
import { authState } from "../../state/authState";
import { paymentBalanceInfo } from "../../state/paymentBalance-state";
import "./styles/account-details.scss";

export const AccountDetails = () => {
  const [authenticationState, setAuthenticationState] =
    useRecoilState(authState);
  const setTransactions = useSetRecoilState(advancePaymentInfo);
  const setPaymentBalanceInformation = useSetRecoilState(paymentBalanceInfo);
  const setAdvancesInformation = useSetRecoilState(advanceInfo);

  const navigate = useNavigate();

  useEffect(() => {
    switch (authenticationState.status) {
      case "logged_out":
      case "idle_logged_out":
        navigate("/");
        break;
    }
  }, [authenticationState]);

  const logout = () => {
    setAuthenticationState({
      status: "logged_out",
    });
    setTransactions([]);
    setAdvancesInformation([]);
    setPaymentBalanceInformation({ paymentBalance: [] });
  };

  const formatPhoneNumber = (phoneNumberString: string) => {
    const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return null;
  };

  const { userName, email, phone } = authenticationState;

  return (
    <div className="accountDetails__Container accountDetails__NoSelection">
      <div className="accountDetails__Heading">Account Info:</div>
      <div>{userName}</div>
      <div>{formatPhoneNumber(phone || "")}</div>
      <div>{email}</div>
      <div>
        <a
          onClick={(e) => {
            e.preventDefault();
            logout();
          }}
          className="accountDetails__Logout"
        >
          Log out
        </a>
      </div>
    </div>
  );
};
