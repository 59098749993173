import "./styles/forgotPassword.scss";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import { useState } from "react";
import { UserAuthAPI } from "../../apis/userAuth";
import { LoadingContainer } from "../loader/loader-container";

type LoginProps = {
  children: JSX.Element | JSX.Element[];
  setDisplayType: any;
};

export const ForgotPasswordContainer = (props: LoginProps): JSX.Element => {
  const backToLoginClick = () => {
    props.setDisplayType("Login");
  };

  const [loadingState, setLoadingState] = useState<
    "loading" | "ready" | "error" | "success"
  >("ready");

  const [message, setMessage] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  return (
    <Row md="12">
      <Col>
        <Form inline className="fade-in-left">
          <FormGroup>
            <Label
              className="forgotPassword__userNameLabel"
              for="forgotPassword__userNameTxt"
            >
              User Name
            </Label>
            <Input
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              id="forgotPassword__userNameTxt"
              name="email"
              placeholder="Enter user name or email"
              type="email"
            />
          </FormGroup>
          <Button
            className="forgotPassword__restorePasswordBtn"
            onClick={async () => {
              try {
                setLoadingState("loading");
                await UserAuthAPI.sendResetPasswordLink({ username: email });
                setMessage("Your restore password email has been sent.");
                setLoadingState("success");
              } catch (err) {
                setMessage("Unable to reset password!");
                setLoadingState("error");
              }
            }}
          >
            Restore Password
          </Button>
          <div className="forgotPassword__backToLoginDiv">
            <small
              className="forgotPassword__backToLoginBtn"
              onClick={() => backToLoginClick()}
            >
              Back to login
            </small>
          </div>
        </Form>
        <LoadingContainer state={loadingState} message={message} />
      </Col>
    </Row>
  );
};
