import { TableColumn } from "react-data-table-component";

function convertArrayOfObjectsToCSV<T>(data: any, columns: TableColumn<T>[]) {
  let result: any;

  const keysToExclude = ["opportunityId"]
  const columnDelimiter = ",";
  const lineDelimiter = "\n";
  const dataKeys = Object.keys(data[0]);
  const downloadableDataKeys = dataKeys.filter( item => !keysToExclude.includes(item) )
  const keys: string[] = downloadableDataKeys.map((key) => {
    switch (key) {
      case "balance":
        return "BALANCE OWING";
      case "purchaseDate":
        return "DATE";
      case "vendorName":
        return "VENDOR";
      case "transactionNumber":
        return "TRANSACTION ID";     
      case "vendorOrderId":
        return "VENDOR ORDER ID";
      case "paymentDate":
        return "PAYMENT DATE";
      case "nsfAmount":
        return "NSF AMOUNT";
      case "totalPaymentAmount":
        return "TOTAL PAYMENT AMOUNT";
      case "paymentAmount":
        return "PAYMENT AMOUNT";
      case "principalAmount":
        return "PRINCIPLE AMOUNT";
      case "paymentStatus":
        return "PAYMENT STATUS";
      case "totalRepaymentAmount":
        return "TOTAL REPAYMENT AMOUNT";
      case "noOfInstallments":
        return "NUMBER OF PAYMENTS";
      case "totalServiceFee":
        return "TOTAL SERVICE FEE";
      case "remainingServiceFee":
        return "REMAINING SERVICE FEE";
      case "remainingPrincipalAmount":
        return "REMAINING PRINCIPAL AMOUNT";
      default:
        return key;
    }
  });
  result = "";
  result += keys.join(columnDelimiter);
  result += lineDelimiter;

  data.forEach((item: any) => {
    let ctr = 0;
    downloadableDataKeys.forEach((key) => {
      if (ctr > 0) result += columnDelimiter;

      result += item[key as keyof T];

      ctr++;
    });
    result += lineDelimiter;
  });

  return result;
}

function DownloadCSV<T>(data: T[], columns: TableColumn<T>[]): void {
  const today = new Date();
  const dd = String(today.getDate()).padStart(2, "0");
  const mm = String(today.getMonth() + 1).padStart(2, "0");
  const yyyy = today.getFullYear();
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const link = document.createElement("a");
  let csv = convertArrayOfObjectsToCSV<T>(data, columns);
  if (csv == null) return;

  const filename = `Export-${
    mm + "-" + dd + "-" + yyyy + "-" + hours + "-" + minutes
  }.csv`;

  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`;
  }

  link.setAttribute("href", encodeURI(csv));
  link.setAttribute("download", filename);
  link.click();
}

export default DownloadCSV;
