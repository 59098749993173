import { matchPath, useLocation } from "react-router";
import routes from "../config/routes";

export const useMatchedRoute = () => {
  const { pathname } = useLocation();
  for (const route of routes) {
    if (matchPath({ path: route.path }, pathname)) {
      return route;
    }
  }
}
