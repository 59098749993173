import './styles/forgotLoginDetails.scss';
import {  Col, Row } from 'reactstrap';


export const ForgotLoginDetails = (props:any): JSX.Element => {
    return(
            <Row md="8" className='login__forgotDetails'>
                <Col className='login__forgotDetails__column__username'>
                    <small className='login__forgotDetails__anchorTag' onClick={()=>{props.setDisplayType("ForgotUsername")}}>Forgot Username?</small>
                </Col>
                <Col className='login__forgotDetails__column__password'>
                    <small className='login__forgotDetails__anchorTag login__forgotDetails_passwordLink' onClick={()=>{props.setDisplayType("ForgotPassword")}}>Forgot password?</small>
                </Col>
            </Row>
    )
}