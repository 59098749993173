import { makeGetRequest, makePostRequest } from ".";
import IAdvanceInformation from "../interfaces/IAdvanceInformation";
import IAdvancePaymentInformation from "../interfaces/IAdvancePaymentInformation";

export const DataAPI: DataApi = {
  getData: async (
    payload: AccountDataRequest,
    accessToken: string
  ): Promise<AccountDataResponse> => {
    const url = `/data/account`;
    return await makePostRequest<AccountDataRequest, AccountDataResponse>(
      payload,
      url,
      true,
      accessToken
    );
  },
  getAdvances: async (accessToken?: string): Promise<AdvancesResponseObj> => {
    const url = `/data/getAdvances`;
    return await makeGetRequest<AdvancesRequest, AdvancesResponseObj>(
      url,
      true,
      accessToken
    );
  },
  getAllTransactionsForOpportunityId: async (
    opportunityId: string,
    accessToken?: string
  ): Promise<AdvancePaymentResponseObj> => {
    const url = `/data/getAdvancePayments?opportunityId=${opportunityId}`;
    return await makeGetRequest<
      AdvancePaymentRequest,
      AdvancePaymentResponseObj
    >(url, true, accessToken);
  },
};

type DataApi = {
  getData: (
    payload: AccountDataRequest,
    accessToken: string
  ) => Promise<AccountDataResponse>;
  getAdvances: (accessToken?: string) => Promise<AdvancesResponseObj>;
  getAllTransactionsForOpportunityId: (
    opportunityId: string,
    accessToken?: string
  ) => Promise<AdvancePaymentResponseObj>;
};

type AccountDataRequest = {};

type AccountDataResponse = {
  data: {};
};

type AdvancesRequest = {
  type: string;
};

type AdvancePaymentRequest = {
  type: string;
};

type AdvancesResponseObj = {
  data: AdvancesResponse[];
};

type AdvancePaymentResponseObj = {
  data: AdvancePaymentResponse[];
};

export type AdvancesResponse = IAdvanceInformation;
export type AdvancePaymentResponse = IAdvancePaymentInformation;
