import { CSSProperties } from "react";
import { RoundedCorner, WidgetProps } from "./types/widget-props";

export const Widget: React.FC<WidgetProps> = (props: WidgetProps) => {
  let cardStyle: CSSProperties = {
    marginTop: props.marginTop ?? 10,
    marginLeft: props.marginLeft ?? 10,
    marginRight: 0,
  };

  const borderRadius = props.borderRadius ?? 50;
  const fixedWidthAndHeight: boolean =
    props.fixedWidthAndHeight !== undefined ? props.fixedWidthAndHeight : true;
  const cornersDisplayStyle: RoundedCorner =
    props.deviceType === "mobile" ? "allCorners" : props.roundedCorners; // we require same border on all sides for mobile devices

  if (fixedWidthAndHeight) {
    cardStyle = {
      ...cardStyle,
      width: props.width ?? 350,
      height: props.height ?? 150,
    };
  } else {
    cardStyle = {
      ...cardStyle,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 10,
      paddingRight: 10,
    };
  }

  switch (cornersDisplayStyle) {
    case "bottomLeftTopRight":
      cardStyle = {
        ...cardStyle,
        borderTopLeftRadius: borderRadius / 5,
        borderBottomRightRadius: borderRadius / 5,
        borderTopRightRadius: borderRadius,
        borderBottomLeftRadius: borderRadius,
        marginRight: 10,
      };
      break;
    case "topLeftBottomRight":
      cardStyle = {
        ...cardStyle,
        borderTopRightRadius: borderRadius / 5,
        borderBottomLeftRadius: borderRadius / 5,
        borderBottomRightRadius: borderRadius,
        borderTopLeftRadius: borderRadius,
        marginRight: 10,
      };
      break;
    case "allCorners":
      cardStyle = {
        ...cardStyle,
        borderRadius: borderRadius / 5,
        marginRight: 10,
      };
      break;
  }

  switch (props.color) {
    case "blue":
      cardStyle = {
        ...cardStyle,
        background: "#C7F2FF",
      };
      break;
    case "green":
      cardStyle = {
        ...cardStyle,
        background: "linear-gradient(270deg, #C4FEDB, #e3ffee)",
      };
      break;
    case "pink":
      cardStyle = {
        ...cardStyle,
        background: "linear-gradient(270deg, #f6deff, #F0C6FF)",
      };
      break;
    case "red":
      cardStyle = {
        ...cardStyle,
        background: "linear-gradient(270deg, #FFD3E2, #ffe3ed)",
      };
      break;
    case "grey":
      cardStyle = {
        ...cardStyle,
        background: "#757575",
      };
      break;
    case "dark":
      cardStyle = { ...cardStyle, color: "white", background: "#13436D" };
      break;
    case "light":
      cardStyle = { ...cardStyle, background: "#fff" };
      break;
    case "transparent":
      cardStyle = {
        ...cardStyle,
        background: "transparent",
        padding: 0,
        marginRight: 0,
      };
      break;
    default:
      cardStyle = {
        ...cardStyle,
        background: "linear-gradient(270deg, #C7F2FF, #def7ff)",
      };
      break;
  }
  return (
    <div className={props.className} style={cardStyle}>
      {props.children}
    </div>
  );
};
