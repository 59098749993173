import { Loader } from "./loader";
import { LoaderContainerProps } from "./types/loader-props";

export const LoadingContainer = (props: LoaderContainerProps) => {
  const { state, message } = props;

  switch (state) {
    case "error":
      return (
        <div className="forgotPassword__message forgotPassword__error">
          <small>{message}</small>
        </div>
      );
    case "loading":
      return (
        <div className="forgotPassword__loader">
          <Loader mode="spinner" />
        </div>
      );
    case "success":
      return (
        <div className="forgotPassword__message">
          <small>{message}</small>
        </div>
      );
    case "ready":
      return <div></div>;
    default:
      throw new Error("Unsupported state: " + state);
  }
};
