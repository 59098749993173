import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import routes from "./config/routes";
import { useIdleTimer } from "react-idle-timer";
import { useRecoilState } from "recoil";
import { authState } from "./state/authState";
import config from "./config/config";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./components/appInsights";

function App() {
  const [authenticationState, setAuthenticationState] =
    useRecoilState(authState);
  const { token } = authenticationState;
  const handleOnIdle = () => {
    if (authenticationState.status === "authorized") {
      setAuthenticationState({
        status: "idle_logged_out",
      });
    }
  };

  useIdleTimer({
    timeout: 1000 * 60 * config.IDLE_TIME_OUT_IN_MINUTES,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  function jwtDecode<T>(token: string) {
    const header = JSON.parse(window.atob(token.split(".")[0]));
    const payload = JSON.parse(window.atob(token.split(".")[1])) as T;
    return { raw: token, header, payload };
  }

  const checkIfTokenIsExpired = (token: string) => {
    const { payload } = jwtDecode<{ exp: number }>(token);
    const expired = Date.now() >= payload.exp * 1000;
    if (expired) handleOnIdle();
    return expired;
  };

  return (
    // TODO: Please refactor this code
    <BrowserRouter>
      <Routes>
        {token && !checkIfTokenIsExpired(token)
          ? routes.map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <route.component name={route.name} {...route.props} />
                  }
                />
              );
            })
          : routes.slice(0, 2).map((route, index) => {
              return (
                <Route
                  key={index}
                  path={route.path}
                  element={
                    <route.component name={route.name} {...route.props} />
                  }
                />
              );
            })}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default withAITracking(reactPlugin, App);
