import CloseIcon from "../icons/close-icon";
import "./styles/modal.scss";

export const CustomModal = (props: ModalProps) => {
  const { content, title, show, closeAction } = props;

  const renderTitle = () => {
    if (title) {
      return <small className="CustomModal__title">{title}</small>;
    }
  };

  const setClassName = () => {
    return show ? `CustomModal__window fade-in` : `CustomModal__none`;
  };

  const setSizeClassName = () => {
    switch (props.size) {
      case "sm":
        return "CustomModal__sm";
      case "md":
        return "CustomModal__md";
      case "lg":
        return "CustomModal__lg";
      default:
        return "CustomModal__sm";
    }
  };

  return (
    <div className={setClassName()}>
      <div className={`CustomModal__container ${setSizeClassName()}`}>
        <div
          className="CustomModal__close"
          onClick={() => {
            if (closeAction) closeAction();
          }}
        >
          <CloseIcon style={{ color: "#0a2b47" }} />
        </div>
        {renderTitle()}
        <div className="CustomModal__content">{content}</div>
      </div>
    </div>
  );
};

export type ModalProps = {
  title?: string;
  size?: "sm" | "md" | "lg";
  content: string | JSX.Element;
  show: boolean;
  closeAction?: () => void;
};
