import { useState } from "react";
import {
  Card,
  CardText,
  CardBody,
  CardTitle,
  Col,
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import { useRecoilState } from "recoil";
import { authState } from "../../state/authState";
import { DeviceType } from "../../utils/deviceType";
import { AccountDetails } from "../accountDetails";
import { AdvancesDatatable } from "../datatable/advanceDatatable";
import { FooterContainer } from "../footer";
import DashboardIcon from "../icons/dashboard-icon";
import SettingsIcon from "../icons/settings-icon";
import SupportIcon from "../icons/support-icon";
import PhoneIcon from "../icons/phone-icon";
import EmailIcon from "../icons/email-icon";
import FaqIcon from "../icons/faq-icon";
import { WidgetsPanel } from "../widget/widgetsPanel";
import { NavigationItemAddonBottom } from "./NavigationItemAddonBottom";
import { NavigationItemAddonTop } from "./NavigationItemAddonTop";
import { NavigationItemProps } from "./types/navigation-props";
import "./styles/navigation.scss";
import { Helmet } from "react-helmet";

export const NavigationContainer = (props: any) => {
  const [selectedTabIndex, setTabIndex] = useState<number>(1);
  const [authenticationState, setAuthState] = useRecoilState(authState);

  const [isCollapsed, setCollapsed] = useState(false);
  const { userName } = authenticationState;

  const displayDeviceType = DeviceType();

  const setBackgroundColor = (tabIndex: number) => {
    return selectedTabIndex === tabIndex ? "white" : "#4AB5D8";
  };

  const setTextColor = (tabIndex: number) => {
    return selectedTabIndex === tabIndex ? "#005887" : "white";
  };

  const toogleMenuMobile = () => {
    setCollapsed(!isCollapsed);
  };

  const NavigationItem = (props: NavigationItemProps) => (
    <>
      {props.requireAddons === true && (
        <NavigationItemAddonTop visible={selectedTabIndex === props.index} />
      )}
      <NavItem>
        <NavLink
          style={{
            backgroundColor: setBackgroundColor(props.index),
            borderColor: setBackgroundColor(props.index),
            color: setTextColor(props.index),
          }}
          className="navigation__LinkItem"
          onClick={() => {
            setTabIndex(props.index);
            toogleMenuMobile();
          }}
        >
          <Row className="g-0">
            <Col sm="12">
              <span>{props.icon} </span>
              <span>{props.title}</span>
            </Col>
          </Row>
        </NavLink>
      </NavItem>
      {props.requireAddons === true && (
        <NavigationItemAddonBottom visible={selectedTabIndex === props.index} />
      )}
    </>
  );

  const AccountDetailsContainer = () => {
    const [state, setState] = useState<"not_set" | "show" | "hide">("not_set");

    const setClassName = () => {
      switch (state) {
        case "not_set":
          return "navigation__TabContent__Initial";
        case "hide":
          return "navigation__TabContent__Initial";
        case "show":
          return "navigation__TabContent__AccountDetailsContainer navigation__TabContent__Show";
        default:
          throw new Error("Unknown status: " + state);
      }
    };
    return (
      <div
        onClick={() => {
          switch (state) {
            case "hide":
            case "not_set":
              setState("show");
              break;
            case "show":
              setState("hide");
              break;
          }
        }}
      >
        <div className="navigation__TabContent__SettingsIcon">
          <SettingsIcon />
        </div>
        <div className={setClassName()}>
          <AccountDetails />
        </div>
      </div>
    );
  };

  const getNameByTitle = (tabIndex: number) => {
    switch (tabIndex) {
      case 1:
        return "Dashboard - Tabit Buyer Portal";
      case 2:
        return "Transactions - Tabit Buyer Portal";
    }
  };

  const GetTabContent = (isHeaderFixed: boolean = true): JSX.Element => {
    return (
      <TabContent
        activeTab={selectedTabIndex}
        className="navigation__TabContent"
      >
        <TabPane tabId={1}>
          <Row>
            <Col sm="12">
              <Card
                body
                className="navigation__TabContent__CardBody"
                style={{ overflowY: "auto" }}
              >
                <AccountDetailsContainer />
                <div className="fade-in-left">
                  <div className="navigation__TabContent__Widgets">
                    <WidgetsPanel selectedLoanId={props.selectedLoanId} />
                  </div>
                  <CardTitle className="navigation__TabContent__CardBody__Dashboard__Heading">
                    TABIT TRANSACTION HISTORY
                  </CardTitle>
                  <AdvancesDatatable
                    fixedHeader={isHeaderFixed}
                    props={props}
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId={2}>
          <Row>
            <Col sm="12">
              <Card
                body
                className="navigation__TabContent__CardBody"
                style={{ overflowY: "auto" }}
              >
                <AccountDetailsContainer />
                <CardTitle className="navigation__TabContent__CardBody__Transaction__Heading">
                  SUPPORT
                </CardTitle>
                <div className="navigation__TabContent__Transactions navigation__TabContent__Transaction__Datatable fade-in-left">
                  <Row>
                    <Col sm="12" lg="4">
                      <a
                        href="https://tabit-faq.helpscoutdocs.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: "none", color: "#8898aa" }}
                      >
                        <Card className="navigation__SupportCard" outline>
                          <CardBody
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <FaqIcon
                              style={{
                                color: "#14436D",
                              }}
                            />
                            <div
                              style={{
                                flex: "1 0",
                              }}
                            >
                              <CardTitle tag="h5">FAQ</CardTitle>
                              <CardText>
                                https://tabit-faq.helpscoutdocs.com/
                              </CardText>
                            </div>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                    <Col sm="12" lg="4">
                      <a
                        href="tel:+18668282248"
                        style={{ textDecoration: "none", color: "#8898aa" }}
                      >
                        <Card className="navigation__SupportCard" outline>
                          <CardBody
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <PhoneIcon
                              style={{
                                color: "#14436D",
                              }}
                            />
                            <div
                              style={{
                                flex: "1 0",
                              }}
                            >
                              <CardTitle tag="h5">Phone</CardTitle>
                              <CardText>+1 866-828-2248</CardText>
                            </div>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                    <Col sm="12" lg="4">
                      <a
                        href="mailto:support@tabit.ai"
                        style={{ textDecoration: "none", color: "#8898aa" }}
                      >
                        <Card className="navigation__SupportCard" outline>
                          <CardBody
                            style={{
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <EmailIcon
                              style={{
                                color: "#14436D",
                              }}
                            />
                            <div
                              style={{
                                flex: "1 0",
                              }}
                            >
                              <CardTitle tag="h5">Email</CardTitle>
                              <CardText>support@tabit.ai</CardText>
                            </div>
                          </CardBody>
                        </Card>
                      </a>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    );
  };

  const GetNavigationItems = (
    isAddonsIncluded: boolean = true
  ): JSX.Element => {
    return (
      <>
        <NavigationItem
          requireAddons={isAddonsIncluded}
          index={1}
          title={"Dashboard"}
          icon={<DashboardIcon style={{ color: setTextColor(1) }} />}
        />
        <NavigationItem
          requireAddons={isAddonsIncluded}
          index={2}
          title={"Support"}
          icon={<SupportIcon style={{ color: setTextColor(2) }} />}
        />
      </>
    );
  };

  const GetFooterItem = (): JSX.Element => {
    return (
      <Row>
        <FooterContainer
          displayCopyright={true}
          displayLegal={true}
          displaySocial={false}
          className="dashboard__Footer"
        />
      </Row>
    );
  };

  return (
    <>
      <Helmet>
        <title>{getNameByTitle(selectedTabIndex)}</title>
      </Helmet>
      {displayDeviceType !== "mobile" && (
        <div className="navigation__Background">
          <Row className="navigation__mainview g-0">
            <Col sm="2">
              <Nav tabs vertical fill className="navigation__TabHeader">
                <NavItem className="navigation__LinkItemHeader">
                  <h3 className="navigation__LinkItemHeader__Title">
                    Welcome,
                  </h3>
                  <h3 className="navigation__LinkItemHeader__Title">
                    {userName}
                  </h3>
                  <img
                    className="navigation__Logo"
                    src={process.env.PUBLIC_URL + "/assests/tabit-1.svg"}
                    alt="tabit_logo"
                  ></img>
                </NavItem>
                {GetNavigationItems()}
              </Nav>
            </Col>
            <Col sm="10">
              <div className="navigation__TabContent__Container">
                {GetTabContent()}
              </div>
            </Col>
          </Row>
          {GetFooterItem()}
        </div>
      )}
      {displayDeviceType === "mobile" && (
        <div className="navigation__Background">
          <Navbar expand="lg" dark>
            <NavbarBrand>Welcome, {userName}</NavbarBrand>
            <NavbarToggler onClick={toogleMenuMobile} />
            <Collapse navbar isOpen={isCollapsed}>
              <Nav tabs className="navigation__mobile__navMenu" navbar>
                {GetNavigationItems(false)}
              </Nav>
            </Collapse>
          </Navbar>
          <Row sm="12">
            <img
              className="navigation__Logo"
              src={process.env.PUBLIC_URL + "/assests/tabit-1.svg"}
              alt="tabit_logo"
            ></img>
          </Row>
          <Row className="navigation__TabContent__CardBody">
            <div className="navigation__TabContent__Container">
              {GetTabContent(true)}
            </div>
          </Row>
          {GetFooterItem()}
        </div>
      )}
    </>
  );
};
