import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DataTable, { TableProps } from "react-data-table-component";
import { UncontrolledTooltip } from "reactstrap";
import downloadCSV from "../../utils/downloadCSV";
import "./styles/Datatable.scss";

export type DataTableExtendedProps<T> = TableProps<T> & {
  hideExportBtn: boolean;
};

const selectProps = {
  indeterminate: (isIndeterminate: boolean) => isIndeterminate,
};

function DataTableBase<T>(props: DataTableExtendedProps<T>): JSX.Element {
  const actionsMemo = React.useMemo(() => {
    const exportHtmlElement = () => {
      if (!props.hideExportBtn) {
        return (
          <div
            id="exportBtnDiv"
            onClick={() => downloadCSV<T>(props.data, props.columns)}
          >
            <FontAwesomeIcon icon={faDownload} />
            <UncontrolledTooltip placement="right" target="exportBtnDiv">
              Click to Export as CSV
            </UncontrolledTooltip>
          </div>
        );
      } else {
        return <></>;
      }
    };
    return <>{exportHtmlElement()}</>;
  }, [props.data]);

  return (
    <DataTable
      pagination
      selectableRowsComponentProps={selectProps}
      onRowClicked={props.onRowDoubleClicked}
      {...props}
      actions={actionsMemo}
    />
  );
}

export default DataTableBase;
