import { Col } from "reactstrap";
import "./styles/loginTabitMapleImage.scss";

export const LoginTabitImage = (): JSX.Element => {
  return (
    <Col sm={6} md={6}>
      <div className="login__tabitMapleLeafContainer fade-in-left">
        <div className="login_tabitMapleLeafText">
          <div>The B2B Buy Now, Pay Later Solution</div>
          <div>For Businesses in Canada.</div>
        </div>
        <img className="login_tabitMapleLeafImg"
          src={process.env.PUBLIC_URL + "/assests/tabit-mapleleaf-vector.svg"}
          alt="Tabit"
        ></img>
      </div>
    </Col>
  );
};
