import { CSSProperties, useEffect, useState } from "react";
import { Widget } from ".";
import { DeviceType } from "../../utils/deviceType";
import { FormatNumber } from "../../utils/Formatter";
import BillIcon from "../icons/bill-icon";
import ScheduleIcon from "../icons/schedule-icon";
import DollarIcon from "../icons/dollar-icon";
import "./styles/widget-panel.scss";
import styles12 from "../../baseSCSS/_variables.module.scss";
import { RoundedCorner } from "./types/widget-props";
import { useRecoilState } from "recoil";
import { paymentBalanceInfo } from "../../state/paymentBalance-state";
import moment from "moment";
import IPaymentBalance from "../../interfaces/IPaymentBalanceInformation";

const notificationContainer: CSSProperties = {
  display: "flex",
  flexDirection: "column",
};

const notificationDetails: CSSProperties = {
  display: "flex",
};

export const WidgetsPanel = (props: any) => {
  const [paymentBalanceInformation, setPaymentBalanceInformation] =
    useRecoilState(paymentBalanceInfo);
  const [currentWidgetInformation, setCurrentWidgetInformation] =
    useState<IPaymentBalance>({
      balance: 0,
      nextPaymentDate: undefined,
      nextPaymentAmount: 0,
    });

  useEffect(() => {
    if (props.selectedLoanId !== undefined && props.selectedLoanId !== "") {
      const GetWidgetInformation = () => {
        let sum = 0;
        if (paymentBalanceInformation.paymentBalance.length > 0) {
          let nextPaymentDate =
            paymentBalanceInformation.paymentBalance[0].nextPaymentDate;
          let nextPaymentAmount =
            paymentBalanceInformation.paymentBalance[0].nextPaymentAmount;
          paymentBalanceInformation.paymentBalance.forEach((v) => {
            sum = sum + v.balance;
            if (!moment(nextPaymentDate).isBefore(v.nextPaymentDate)) {
              nextPaymentDate = v.nextPaymentDate;
              nextPaymentAmount = v.nextPaymentAmount;
            }
          });
          setCurrentWidgetInformation({
            balance: sum,
            nextPaymentDate: nextPaymentDate || undefined,
            nextPaymentAmount,
          });
        }
      };
      GetWidgetInformation();
    }
  }, []);

  const displayDeviceType: string = DeviceType();

  const renderWidget = (
    roundedCorners: RoundedCorner,
    content: number | string,
    description: string,
    type: "currency" | "integer" | "text",
    color: string,
    icon?: JSX.Element,
    width?: number
  ) => {
    return (
      <div style={notificationContainer}>
        <div style={notificationDetails}>
          {renderIcon(roundedCorners, icon)}
          {renderContent(content, description, type, color, width)}
        </div>
      </div>
    );
  };

  const renderContent = (
    content: number | string,
    description: string,
    type: "currency" | "integer" | "text",
    color: string,
    width?: number
  ) => {
    let contentToRender = null;
    switch (typeof content) {
      case "number":
        contentToRender = FormatNumber(content, type);
        break;
      default:
        contentToRender = <div>{content}</div>;
        break;
    }
    return (
      <div
        style={{
          width: width,
          textAlign: "center",
          color: color,
        }}
      >
        <div style={{ fontSize: 25, fontWeight: 500 }}>{contentToRender}</div>
        <div>{description}</div>
      </div>
    );
  };

  const renderIcon = (roundedCorners: RoundedCorner, icon?: JSX.Element) => {
    if (icon != null) {
      return (
        <Widget
          roundedCorners={roundedCorners}
          color="transparent"
          fixedWidthAndHeight={false}
          deviceType={displayDeviceType}
        >
          {icon}
        </Widget>
      );
    }
  };

  const isNextPaymentDateValid = (nextPaymentDate: Date|undefined) => {
    if(nextPaymentDate === undefined)
      return false;
     return  moment(nextPaymentDate).isValid();
  };

  return (
    <div className="home-body-cardList-div">
      <Widget
        deviceType={displayDeviceType}
        className="home-body-card-div"
        roundedCorners="topLeftBottomRight"
        color="dark"
        height={
          displayDeviceType === "mobile"
            ? parseInt(styles12.widget_Mobile_Width)
            : undefined
        }
      >
        {renderWidget(
          "topLeftBottomRight",
          currentWidgetInformation === undefined
            ? 0
            : currentWidgetInformation.balance,
          `Tabit Balance`,
          "currency",
          "white",
          <BillIcon style={{}} />
        )}
      </Widget>
      <Widget
        deviceType={displayDeviceType}
        className="home-body-card-div"
        roundedCorners="bottomLeftTopRight"
        color="blue"
        height={
          displayDeviceType === "mobile"
            ? parseInt(styles12.widget_Mobile_Width)
            : undefined
        }
      >
        {renderWidget(
          "topLeftBottomRight",
            !isNextPaymentDateValid(currentWidgetInformation.nextPaymentDate)
            ? "N/A"
            : moment(currentWidgetInformation.nextPaymentDate).format("ll"),
            !isNextPaymentDateValid(currentWidgetInformation.nextPaymentDate)
            ? "Next Payment Date"
            : moment(currentWidgetInformation.nextPaymentDate) < moment()
            ? "Last Payment Date"
            : "Next Payment Date",
          "currency",
          "#13436D",
          <ScheduleIcon style={{}} />
        )}
      </Widget>
      <Widget
        deviceType={displayDeviceType}
        className="home-body-card-div"
        roundedCorners="bottomLeftTopRight"
        color="grey"
        height={
          displayDeviceType === "mobile"
            ? parseInt(styles12.widget_Mobile_Width)
            : undefined
        }
      >
        {
        renderWidget(
          "bottomLeftTopRight",
          currentWidgetInformation === undefined
            ? 0
            : currentWidgetInformation.nextPaymentAmount,
            !isNextPaymentDateValid(currentWidgetInformation.nextPaymentDate)
            ? "Next Payment Amount"
            : moment(currentWidgetInformation.nextPaymentDate) < moment()
            ? "Last Payment Amount"
            : "Next Payment Amount",
          "currency",
          "white",
          <DollarIcon style={{ color: "white" }} />
        )}
      </Widget>
    </div>
  );
};
