import { atom } from "recoil";
import { recoilPersist } from "recoil-persist";
import IAdvanceInformation from "../interfaces/IAdvanceInformation";

const { persistAtom } = recoilPersist();

export const advanceInfo = atom<AdvanceInfo[]>({
  key: "advanceInfo",
  default: [],
  effects_UNSTABLE: [persistAtom]
});

export type AdvanceInfo = IAdvanceInformation
