import { IconProps } from "./types/icon-props";

const CloseIcon = (props: IconProps) => {
  const { style } = props;

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 10.6868 21.7413 9.38642 21.2388 8.17317C20.7363 6.95991 19.9997 5.85752 19.0711 4.92893C18.1425 4.00035 17.0401 3.26375 15.8268 2.7612C14.6136 2.25866 13.3132 2 12 2V2ZM12 20C10.4178 20 8.87104 19.5308 7.55544 18.6518C6.23985 17.7727 5.21447 16.5233 4.60897 15.0615C4.00347 13.5997 3.84504 11.9911 4.15372 10.4393C4.4624 8.88743 5.22433 7.46197 6.34315 6.34315C7.46197 5.22433 8.88743 4.4624 10.4393 4.15372C11.9911 3.84504 13.5997 4.00346 15.0615 4.60896C16.5233 5.21447 17.7727 6.23984 18.6518 7.55544C19.5308 8.87103 20 10.4177 20 12C20 14.1217 19.1572 16.1566 17.6569 17.6569C16.1566 19.1571 14.1217 20 12 20V20Z"
        fill={style.color}
      />
      <path
        d="M14.7099 9.28994C14.617 9.19621 14.5064 9.12182 14.3845 9.07105C14.2627 9.02028 14.132 8.99414 13.9999 8.99414C13.8679 8.99414 13.7372 9.02028 13.6154 9.07105C13.4935 9.12182 13.3829 9.19621 13.2899 9.28994L11.9999 10.5899L10.7099 9.28994C10.5216 9.10164 10.2662 8.99585 9.99994 8.99585C9.73364 8.99585 9.47824 9.10164 9.28994 9.28994C9.10164 9.47824 8.99585 9.73364 8.99585 9.99994C8.99585 10.2662 9.10164 10.5216 9.28994 10.7099L10.5899 11.9999L9.28994 13.2899C9.19621 13.3829 9.12182 13.4935 9.07105 13.6154C9.02028 13.7372 8.99414 13.8679 8.99414 13.9999C8.99414 14.132 9.02028 14.2627 9.07105 14.3845C9.12182 14.5064 9.19621 14.617 9.28994 14.7099C9.3829 14.8037 9.4935 14.8781 9.61536 14.9288C9.73722 14.9796 9.86793 15.0057 9.99994 15.0057C10.132 15.0057 10.2627 14.9796 10.3845 14.9288C10.5064 14.8781 10.617 14.8037 10.7099 14.7099L11.9999 13.4099L13.2899 14.7099C13.3829 14.8037 13.4935 14.8781 13.6154 14.9288C13.7372 14.9796 13.8679 15.0057 13.9999 15.0057C14.132 15.0057 14.2627 14.9796 14.3845 14.9288C14.5064 14.8781 14.617 14.8037 14.7099 14.7099C14.8037 14.617 14.8781 14.5064 14.9288 14.3845C14.9796 14.2627 15.0057 14.132 15.0057 13.9999C15.0057 13.8679 14.9796 13.7372 14.9288 13.6154C14.8781 13.4935 14.8037 13.3829 14.7099 13.2899L13.4099 11.9999L14.7099 10.7099C14.8037 10.617 14.8781 10.5064 14.9288 10.3845C14.9796 10.2627 15.0057 10.132 15.0057 9.99994C15.0057 9.86793 14.9796 9.73722 14.9288 9.61536C14.8781 9.4935 14.8037 9.3829 14.7099 9.28994V9.28994Z"
        fill={style.color}
      />
    </svg>
  );
};
export default CloseIcon;
