import "./styles/login.scss";
import { Form, FormGroup, Label, Input, Button, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUnlock } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";


type LoginProps = {
  children?: JSX.Element | JSX.Element[];
  login: ( e:React.MouseEvent<HTMLInputElement, MouseEvent>, username: string, password: string) => void;
};

type ResetPasswordProps = {
  title: string;
}

export const SignIn = (): JSX.Element => {
  return (
    <Row>
      <Col className="login__signInDiv">Sign-in</Col>
    </Row>
  );
};

export const ResetPassword = (props: ResetPasswordProps): JSX.Element => {
  return (
    <Row>
      <Col className="login__resetPasswordDiv">{props.title}</Col>
    </Row>
  );
}

export const LoginForm = (props: LoginProps): JSX.Element => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  return (
    <Row md="12">
      <Col>
        <Form inline >
          <FormGroup>
            <Label className="login__userNameLabel" for="login__userNameTxt">
              User Name
            </Label>
            <Input
              id="login__userNameTxt"
              name="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Enter user name or email"
              type="text"
            />
          </FormGroup>
          <FormGroup>
            <Label className="login__passwordLabel" for="login__passwordTxt">
              <FontAwesomeIcon icon={faUnlock} /> Password
            </Label>
            <Input
              id="login__passwordTxt"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter password"
              type="password"
            />
          </FormGroup>
          <Input 
            value=" Sign me in!"   
            type="submit"        
            className="login__signInBtn"
            onClick={async (e) => props.login(e, username, password)}>           
          </Input>
        </Form>
      </Col>
    </Row>
  );
};
