import IRoute from "../interfaces/IRoute";
import MainPage from "../pages/main";
import LoginPage from "../pages/login";


const routes : IRoute[] = [
    {
        path:'/',
        name:'Login Page',
        component: LoginPage,
        exact: true
    },
    {
        path:'/reset',
        name:'Reset Password',
        component: LoginPage,
        exact: true,
    },
    {
        path:'/main',
        name:'Main Page',
        component: MainPage,
        exact: true
    }
]

export default routes;