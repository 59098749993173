import axios from "axios";

let apiEndpointBaseUrl: string | undefined;
switch (process.env.REACT_APP_ENVIRONMENT_TYPE) {
  case "local":
  case "development":
  case "production":
    apiEndpointBaseUrl = process.env.REACT_APP_BASE_URL;
    break;
  default:
    throw new Error("Unknown environment!");
}

const makePostRequest = async <Request, Response>(
  payload: Request,
  url: string,
  useCredentials: boolean,
  token?: string
) => {
  return await axios.post<Request, Response>(
    apiEndpointBaseUrl + url,
    payload,
    {
      withCredentials: useCredentials,
      headers: token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {},
    }
  );
};

const makeGetRequest = async <Request, Response>(
  url: string,
  useCredentials: boolean,
  token?: string
) => {
  return await axios.get<Request, Response>(apiEndpointBaseUrl + url, {
    withCredentials: useCredentials,
    headers: token
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {},
  });
};

export { makeGetRequest, makePostRequest };
