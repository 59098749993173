import { Col, Row } from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import "./styles/recaptcha.scss";
import CONFIGURATION from "../../config/config";
import React, { LegacyRef } from "react";
import { DeviceType } from "../../utils/deviceType";

export const Recaptcha = React.forwardRef(
  (props: RecaptchaProps, ref: LegacyRef<ReCAPTCHA>) => {
    const { onChange, onExpired } = props;
    const displayDeviceType = DeviceType();

    let recaptchaSize: "invisible" | "normal" = "invisible";
    // switch (displayDeviceType) {
    //   case "mobile":
    //     recaptchaSize = "normal";
    //     break;
    //   default:
    //     recaptchaSize = "invisible";
    //     break;
    // }

    return (
      <Row md="12">
        <Col>
          <ReCAPTCHA
            ref={ref}
            className="login__recaptcha"
            size={recaptchaSize}
            sitekey={CONFIGURATION.GOOGLE_RECAPTCHA_SITE_KEY}
            onChange={onChange}
            onExpired={onExpired}
          />
        </Col>
      </Row>
    );
  }
);

type RecaptchaProps = {
  ref: any;
  onExpired: () => void;
  onChange: (response: string | null) => void;
};
